import { LOG_OUT } from '../../features/auth/actionTypes';
import { CLOSE_PROFILE, SHOW_PROFILE } from './actionTypes';

const initState = {
	showProfile: false,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SHOW_PROFILE:
			return {
				...state,
				showProfile: true,
			};
		case CLOSE_PROFILE:
			return {
				...state,
				showProfile: false,
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
