import { LOG_OUT } from '../auth/actionTypes';
import {
	CLEAN_REDUCER,
	DELETE_BRANDING,
	FETCH_BRANDING_COUNT,
	FETCH_BRANDINGS,
	SAVE_BRANDINGS,
	UPDATE_BRANDING,
} from './actionTypes';

const initState = {
	brandings: [],
	brandingCount: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SAVE_BRANDINGS:
		case DELETE_BRANDING:
		case FETCH_BRANDINGS:
		case UPDATE_BRANDING:
			return {
				...state,
				brandings: action.brandings,
			};
		case FETCH_BRANDING_COUNT:
			return {
				...state,
				brandingCount: action.count,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
