import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Col, Empty, message, Row, Spin } from 'antd';
import { RootState } from '../../../../store/configureStore';
import { dateToString, formatDate, getCurrentDate, getUtcStartAndEndOfDate } from '../../../../utilities/dateUtils';
import { slashToHyphenFormat } from '../../../../utilities/util';
import PieChart, { DataSets } from '../../../components/charts/PieChart';
import {
	COLOR_STATUS_MAP,
	DAY_MONTH_YEAR_FORMAT,
	YEAR_MONTH_DAY_FORMAT,
	YEAR_MONTH_DAY_TIME_FORMAT,
} from '../../../constants';
import { Task } from '../../../entities/tasks.entity';
import { useAppSelector } from '../../../hooks/redux.hooks';
import { EntityFilter } from '../../../interfaces';
import { TaskService } from '../../../services';

const DATE_FORMAT = YEAR_MONTH_DAY_FORMAT;

const currentDate = getCurrentDate().format(DATE_FORMAT);
const formatSearchFilter = (): EntityFilter<Task> => {
	const { startOfDate: startOfDay, endOfDate: endOfDay } = getUtcStartAndEndOfDate(currentDate, DATE_FORMAT);
	return {
		where: [
			{
				dueDate: {
					op: 'between',
					value: { from: startOfDay, to: endOfDay },
				},
			},
		],
	};
};

const backgroundColor = [
	COLOR_STATUS_MAP.DONE,
	COLOR_STATUS_MAP.SKIPPED,
	COLOR_STATUS_MAP.UNASSIGNED,
	COLOR_STATUS_MAP.TO_DO,
];

const DailyChart = () => {
	const intl = useIntl();
	const systemConfig = useAppSelector((state: RootState) => state.auth.user.unitSystemConfig);
	const dateFormat = slashToHyphenFormat(systemConfig);
	const today = getCurrentDate().format(dateFormat);

	const [totalData, setTotalData] = useState<number>(0);
	const [loader, setLoader] = useState<boolean>(false);
	const [dataSets, setDataSets] = useState<DataSets>({ labels: [], data: [] });

	const fetchTaskStatus = async () => {
		try {
			setLoader(true);
			const today = formatDate(DAY_MONTH_YEAR_FORMAT, new Date());
			const { startOfDate: startOfToday } = getUtcStartAndEndOfDate(today, DAY_MONTH_YEAR_FORMAT, 'day');
			const startOfTodayString = dateToString(startOfToday, YEAR_MONTH_DAY_TIME_FORMAT);

			const response = await TaskService.getStatusCount(formatSearchFilter(), startOfTodayString);
			if (response.success) {
				const { total, uncompleted, ...other } = response.success;

				const labels = Object.keys(other).map((label) => intl.formatMessage(translate(label + 'Tasks')));
				const data = Object.values(other);

				setDataSets({ labels, data });
				setTotalData(total);
			} else {
				message.error(intl.formatMessage(translate('commonError')));
			}
		} catch (e) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		fetchTaskStatus();
	}, []);

	return (
		<div className="daily-chart overview-card">
			<Row className="overview-title">
				<FormattedMessage {...translate('taskOfTheDay')} />
				<Col span={24} className="date-subtitle">
					{today}
				</Col>
			</Row>
			<Row className="chart-container">
				<Spin spinning={loader} className="spinner" size="default">
					{totalData === 0 ? (
						<Empty className="empty" description={intl.formatMessage(translate('noTaskForToday'))} />
					) : (
						<div className="pie">
							<PieChart
								dataSets={dataSets}
								labelOnHover={intl.formatMessage(translate('tasks'))}
								backgroundColors={backgroundColor}
							/>
						</div>
					)}
				</Spin>
			</Row>
		</div>
	);
};

export default DailyChart;
