import { ActionType } from '@/types/actionType';
import { ROLES } from '../../constants/global';
import { IMPERSONATE_SUCCESS } from '../impersonate/actionTypes';
import {
	IMPERSONATE,
	IS_AUTHENTICATED,
	LOG_IN,
	LOG_OUT,
	PROFILE_IMAGE,
	REMOVE_AUTHENTICATION,
	SET_UNIT_SYSTEM,
	UPDATE_REFRESH_TOKEN,
	UPDATE_USER_DASHBOARD_CONFIG,
	USER_FORCE_PASSWORD,
} from './actionTypes';

const initState = {
	user: {
		name: '',
	},
	client: {
		id: 0,
	},
	company: {
		id: 0,
	},
	isAuthenticated: false,
	roles: [],
	isMaster: false,
	token: '',
	refreshToken: '',
	expiresIn: '',
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case USER_FORCE_PASSWORD:
			return {
				...state,
				user: {
					...state.user,
					...action.user,
				},
			};
		case PROFILE_IMAGE: {
			return {
				...state,
				user: {
					...state.user,
					image: true,
				},
			};
		}
		case IMPERSONATE:
		case IMPERSONATE_SUCCESS:
		case LOG_IN:
			return {
				...state,
				user: {
					...state.user,
					...action.user,
				},
				company: action.company,
				isMaster: action.userRoles?.includes(ROLES.MASTER),
				roles: action.userRoles,
				client: action.client,
				token: action.token,
				refreshToken: action.refreshToken,
				expiresIn: action.expiresIn,
			};
		case IS_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: true,
			};
		case REMOVE_AUTHENTICATION:
			return {
				...state,
				isAuthenticated: false,
			};
		case SET_UNIT_SYSTEM:
			return {
				...state,
				user: {
					...state.user,
					unitSystemConfig: action.unitSystemConfig,
				},
			};
		case UPDATE_USER_DASHBOARD_CONFIG:
			return {
				...state,
				user: {
					...state.user,
					...action.user,
				},
			};
		case UPDATE_REFRESH_TOKEN:
			return {
				...state,
				token: action.token,
				refreshToken: action.refreshToken,
				expiresIn: action.expiresIn,
				roles: action.roles,
			};
		case ActionType.USER_UPDATE_PROFILE:
			return {
				...state,
				user: {
					...state.user,
					...action.user,
				},
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
