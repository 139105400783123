import { asyncActionType } from '../../utilities/util';

export const GET_CLIENT_ADMINS = asyncActionType('GET_CLIENT_ADMINS');
export const ADD_CLIENT_ADMIN = asyncActionType('ADD', 'CLIENT_ADMIN');
export const EDIT_CLIENT_ADMIN = asyncActionType('EDIT', 'CLIENT_ADMIN');
export const DELETE_CLIENT_ADMIN = asyncActionType('DELETE', 'CLIENT_ADMIN');
export const SELECT_CLIENT_ADMINS = asyncActionType('SELECT', 'CLIENT_ADMINS');
export const SEARCH_CLIENT_ADMINS = asyncActionType('SEARCH', 'CLIENT_ADMINS');

export const GET_CLIENT_ADMINS_USERS = asyncActionType('GET', 'CLIENT_ADMINS_USERS');
export const ADD_CLIENT_ADMIN_USER = asyncActionType('ADD', 'CLIENT_ADMIN_USER');
export const EDIT_CLIENT_ADMIN_USER = asyncActionType('EDIT', 'CLIENT_ADMIN_USER');
export const EDIT_CLIENT_ADMIN_USER_ROLES = asyncActionType('EDIT', 'CLIENT_ADMIN_USER_ROLES');
