import { IMPERSONATE, LOG_OUT } from '../auth/actionTypes';
import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	LAST_EVENTS,
	LAST_GEOFENCE_EVENTS,
	LAST_REPORT,
	MAINTENANCES_LAST_REPORT,
	UPDATE_MAINTENANCES_LAST_REPORT,
} from './actionTypes';

const initState = {
	lastReport: [],
	maintenancesLastReport: [],
	positionsWithEvents: [],
	lastGeofencesEvents: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case LAST_EVENTS:
			return {
				...state,
				positionsWithEvents: action.payload,
			};
		case LAST_GEOFENCE_EVENTS:
			return {
				...state,
				lastGeofencesEvents: action.payload,
			};
		case LAST_REPORT:
			return {
				...state,
				lastReport: action.lastReport,
			};
		case UPDATE_MAINTENANCES_LAST_REPORT:
		case MAINTENANCES_LAST_REPORT:
			return {
				...state,
				maintenancesLastReport: action.maintenancesLastReport,
			};
		case LOG_OUT:
		case IMPERSONATE:
		case CLEAN_REDUCER:
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
