import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DeleteFilled } from '@ant-design/icons';
import { Button, Col, Row, Space, TimePicker, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { capitilizeFirstLetter } from '@/utilities';
import { getWeekdaysTitles } from '@/utilities/dateUtils';
import { AuthorizedSchedule } from './AuthorizedScheduleConfig';

type RangeValue = [Moment | null, Moment | null] | null;

type Props = {
	from: Date | string;
	to: Date | string;
	days: number[];
	onScheduleChange: (schedule: AuthorizedSchedule) => void;
	onDelete: () => void;
	disableDelete: boolean;
	disabled: boolean;
};

const AuthorizedSchedulePicker = ({ from, to, days, onScheduleChange, onDelete, disableDelete, disabled }: Props) => {
	const intl = useIntl();

	const weekDays = getWeekdaysTitles(intl.locale);

	const updateSchedule = (scheduleDay: number) => {
		if (days.includes(scheduleDay)) {
			onScheduleChange({ from, to, days: days.filter((day) => day !== scheduleDay) });
		} else {
			onScheduleChange({ from, to, days: [...days, scheduleDay] });
		}
	};
	const onTimePickerChange = useCallback(
		(values: RangeValue) => {
			if (!values) {
				return;
			}
			const from = values[0]?.format('HH:mm') ?? '00:00';
			const to = values[1]?.format('HH:mm') ?? '00:00';

			onScheduleChange({ from, to, days: days });
		},
		[onScheduleChange, days]
	);

	const WeekDaysButtons = weekDays.map((day, index) => (
		<Tooltip key={index} title={capitilizeFirstLetter(day)}>
			<Button
				type={days.includes(index) ? 'link' : 'text'}
				size="small"
				onClick={() => updateSchedule(index)}
				className="authorize-schedule-config-cards-container-weeks-day"
				disabled={disabled}
			>
				{day.charAt(0).toUpperCase()}
			</Button>
		</Tooltip>
	));

	const RangePicker = useMemo(
		() => (
			<TimePicker.RangePicker
				size="small"
				defaultValue={[moment(from, 'HH:mm'), moment(to, 'HH:mm')]}
				clearIcon={false}
				onChange={onTimePickerChange}
				format="h:mm A"
				disabled={disabled}
			/>
		),
		[from, to, onTimePickerChange]
	);

	return (
		<Row justify="space-between" className="authorize-schedule-config-cards-container">
			<Col span={12}>{RangePicker}</Col>
			<Col span={12} className="authorize-schedule-config-cards-container-weeks">
				<Space size={'small'}>
					{WeekDaysButtons}
					<Button
						key="delete"
						type="text"
						size="small"
						className="authorize-schedule-config-cards-container-weeks-delete"
						icon={<DeleteFilled />}
						onClick={onDelete}
						disabled={disableDelete}
					/>
				</Space>
			</Col>
		</Row>
	);
};

export default AuthorizedSchedulePicker;
