import React from 'react';
import { Col, Row } from 'antd';
import DailyChart from './components/DailyChart';
import PendingPastTasks from './components/PendingPastTasks';
import QuickAccess from './components/QuickAccess';
import TasksCalendar from './components/TasksCalendar';

const Overview = () => {
	return (
		<div className="overview-background">
			<Row gutter={[16, 16]} className="overview">
				<Col
					xs={24}
					sm={24}
					md={12}
					lg={12}
					xl={12}
					className="overview-col inner-space calendar-pending-cards"
				>
					<TasksCalendar />
					<PendingPastTasks />
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className="overview-col inner-space">
					<QuickAccess />
					<DailyChart />
				</Col>
			</Row>
		</div>
	);
};

export default Overview;
