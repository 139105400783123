import axios from 'axios';
import { locale } from '../../constants/global';
import { SET_LANGUAGE } from './actionTypes';

export const FetchLanguage = (language) => (dispatch) => {
	dispatch({
		type: SET_LANGUAGE,
		language: language,
		messages: locale[language].messages,
	});
};

export const SetLanguage = (language) => (dispatch, getState) => {
	let state = getState();
	let user = {
		...state.auth.user,
	};
	let lang = state.language.list;

	if (language === 'en_US') user.languageId = lang[0].id;
	else if (language === 'es_ES') user.languageId = lang[1].id;

	dispatch(FetchLanguage(language));
	axios.patch(`/Accounts/${user.id}`, { languageId: user.languageId });
};
