import { LOG_OUT } from '../auth/actionTypes';
import { CLEAN_REDUCER, DELETE_SCHEDULES, EDIT_SCHEDULES, FETCH_SCHEDULES } from './actionTypes';

const initState = {
	schedules: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case DELETE_SCHEDULES:
		case EDIT_SCHEDULES:
		case FETCH_SCHEDULES:
			return {
				...state,
				schedules: action.schedules,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
