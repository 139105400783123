import { ActionType } from '../../types/actionType';
import { Action } from './actions';
import { CameraDto } from './camera.dto';

type State = {
	list: CameraDto[];
};

const initialState: State = {
	list: [],
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.CAMERA_FETCH_INFO: {
			return {
				...state,
				list: action.payload as CameraDto[],
			};
		}
		case ActionType.CLEAN_REDUCER:
		case ActionType.LOG_OUT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
