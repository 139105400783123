import { LOG_OUT } from '../auth/actionTypes';
import { CLEAN_REDUCER, FETCH_COMPANY_COUNTRIES, FETCH_COUNTRIES, FETCH_SERVICES } from './actionTypes';

const initState = {
	twilio: {},
	sendGrid: {},
	bing: {},
	countries: [],
	companyCountries: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_SERVICES:
			return {
				...state,
				...action.services,
			};
		case FETCH_COUNTRIES:
			return {
				...state,
				countries: action.countries,
			};
		case FETCH_COMPANY_COUNTRIES:
			return {
				...state,
				companyCountries: action.countries,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
