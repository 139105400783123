import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ControlOutlined, UserOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { Col, Row } from 'antd';
import QuickAccessButton from './QuickAccessButton';

const QuickAccess = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	return (
		<div className="quick-access">
			<Row className="overview-title">
				<FormattedMessage {...translate('quickAccess')} />
			</Row>
			<Row gutter={[8, 8]} justify="space-between">
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<QuickAccessButton
						icon={<ControlOutlined />}
						label={intl.formatMessage(translate('taskManagement'))}
						onClick={() => navigate('/optimus-routes/tasks-management')}
						className="tasks-management buttons"
					/>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<QuickAccessButton
						icon={<span className="icon-sukarneTrips" />}
						label={intl.formatMessage(translate('taskPlanner'))}
						onClick={() => navigate('/optimus-routes/task-planner')}
						className="task-planner buttons"
					/>
				</Col>
			</Row>
			<Row className="members-progress-btn">
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<QuickAccessButton
						icon={<UserOutlined />}
						label="Team Members Progess"
						onClick={() => navigate('/optimus-routes/team-progress')}
						className="tasks-management buttons"
					/>
				</Col>
			</Row>
		</div>
	);
};

export default QuickAccess;
