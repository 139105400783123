import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_EVENTS_SUCCESS,
	CLEAR_SESSION,
	EDIT_EVENTS_SUCCESS,
	EVENTS_STATUS,
	FETCH_EVENTS,
	FETCH_EVENTS_COUNT,
} from './actionTypes';

const initState = {
	events: [],
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_EVENTS:
			return {
				...state,
				events: action.events,
			};
		case FETCH_EVENTS_COUNT:
			return {
				...state,
				count: action.count,
			};
		case ADD_EVENTS_SUCCESS: {
			let added = state.events.slice();
			if (state.count === 10) added.pop();
			added.unshift(action.newEvent);
			return {
				...state,
				events: added,
				count: state.count + 1,
			};
		}
		case EDIT_EVENTS_SUCCESS: {
			let eventsList = state.events.map((element) =>
				element.id !== action.events.id ? { ...element } : { ...action.events }
			);
			return {
				...state,
				events: eventsList,
			};
		}
		case EVENTS_STATUS: {
			let eventsList = state.events.map((element) => {
				return element.id !== action.id ? { ...element } : { ...element, active: !element.active };
			});
			return {
				...state,
				events: eventsList,
			};
		}

		case LOG_OUT:
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
