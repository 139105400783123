import { LOG_OUT } from '../auth/actionTypes';
import { CLEAN_REDUCER } from './actionTypes';

const initState = {
	devices: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
