import { CLEAN_REDUCER } from '../../constants/actionTypes';
import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_GAS_STATION_SUCCESS,
	EDIT_GAS_STATION_SUCCESS,
	FETCH_GAS_STATION_COUNT,
	FETCH_GAS_STATIONS,
} from './actionTypes';

const initState = {
	list: [],
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_GAS_STATIONS:
			return {
				...state,
				list: action.payload,
			};
		case FETCH_GAS_STATION_COUNT:
			return {
				...state,
				count: action.count,
			};
		case ADD_GAS_STATION_SUCCESS:
			return {
				...state,
				list: [action.payload, ...state.list],
				count: state.count + 1,
			};
		case EDIT_GAS_STATION_SUCCESS: {
			let edit = state.list.map((element) => {
				if (element.id !== action.payload.id) {
					return {
						...element,
					};
				}
				return {
					...element,
					...action.payload,
				};
			});
			return {
				...state,
				list: edit,
			};
		}
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
