import Unit from './unit';

class distanceUnit extends Unit {
	constructor({ conversion = 1, units = ['', ''], decimal = 0, convert = false }) {
		super({ conversion, units, decimal, convert });
	}

	reverse = () => {
		return new distanceUnit({
			decimal: this.getDecimal(),
			conversion: 1 / this.getConversion(),
			units: [...this.getUnits()].reverse(),
			convert: true,
		});
	};

	toMeters = (value) => {
		return +(this.getCurrentUnit() === 'Mi' ? value * 1609.344 : value * 1000);
	};

	revertFromMeters = (value) => {
		return +(this.getCurrentUnit() === 'Mi' ? value / 1609.344 : value / 1000);
	};
}

export default distanceUnit;
