import { LOG_OUT } from '../auth/actionTypes';
import {
	CLEAN_REDUCER,
	FETCH_ACCOUNT_SERVICES,
	FETCH_ALERT_DEVICES,
	FETCH_ALERT_DEVICES_EVENTS,
	FETCH_CLIENTS_ACCOUNTS,
	FETCH_EVENTS,
	FETCH_EVENTS_DEVICES,
} from './actionTypes';

const initState = {
	devices: [],
	devicesEvents: [],
	events: [],
	eventsDevices: [],
	accounts: [],
	services: {},
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_ALERT_DEVICES:
			return {
				...state,
				devices: action.devices,
			};
		case FETCH_ALERT_DEVICES_EVENTS:
			return {
				...state,
				devicesEvents: action.events,
			};
		case FETCH_CLIENTS_ACCOUNTS:
			return {
				...state,
				accounts: action.accounts,
			};
		case FETCH_EVENTS:
			return {
				...state,
				events: action.events,
			};
		case FETCH_EVENTS_DEVICES:
			return {
				...state,
				eventsDevices: action.devices,
			};
		case FETCH_ACCOUNT_SERVICES:
			return {
				...state,
				services: action.services,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
