const initState = {
	date: undefined,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'SET_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'CLEAN_REDUCER':
		case 'LOG_OUT':
			return initState;
		default:
			return state;
	}
};

export default reducer;
