import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_MODELS_SUCCESS,
	CLEAR_SESSION,
	DELETE_MODELS_SUCCESS,
	DEVICE_MODEL_STATUS,
	EDIT_MODELS_SUCCESS,
	FETCH_BRANDS,
	FETCH_COMMANDS_TYPES,
	FETCH_DEVICE_MODELS,
	FETCH_DEVICE_MODELS_COUNT,
	FETCH_MODEL_SEND_TYPES,
} from './actionTypes';

const initState = {
	deviceModels: [],
	brands: [],
	modelSendTypes: [],
	commandTypes: [],
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_DEVICE_MODELS:
			return {
				...state,
				deviceModels: action.deviceModels,
			};
		case FETCH_DEVICE_MODELS_COUNT:
			return {
				...state,
				count: action.count,
			};
		case EDIT_MODELS_SUCCESS: {
			return {
				...state,
				deviceModels: action.deviceModels,
			};
		}
		case ADD_MODELS_SUCCESS: {
			return {
				...state,
				deviceModels: action.deviceModels,
				count: state.count + 1,
			};
		}
		case DELETE_MODELS_SUCCESS: {
			return {
				...state,
			};
		}
		case FETCH_BRANDS:
			return {
				...state,
				brands: action.brands,
			};
		case FETCH_MODEL_SEND_TYPES:
			return {
				...state,
				modelSendTypes: action.modelSendTypes,
			};
		case FETCH_COMMANDS_TYPES:
			return {
				...state,
				commandTypes: action.commandTypes,
			};
		case DEVICE_MODEL_STATUS: {
			let deviceModelsList = state.deviceModels.map((element) => {
				return element.id !== action.id ? { ...element } : { ...element, active: !element.active };
			});
			return {
				...state,
				deviceModels: deviceModelsList,
			};
		}
		case LOG_OUT:
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
