import { CLEAN_REDUCER } from '../../constants/actionTypes';
import { LOG_OUT } from '../auth/actionTypes';
import { ADD_CLIENTS_SUCCESS, FETCH_CLIENTS } from './actionTypes';

const initState = {
	list: [],
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_CLIENTS:
			return {
				...state,
				list: action.payload,
			};
		case ADD_CLIENTS_SUCCESS: {
			let added = state.list.slice();
			if (state.count === 10) added.pop();
			added.unshift(action.payload);
			return {
				...state,
				list: added,
				count: state.count + 1,
			};
		}
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
