import React from 'react';
import { Spin } from 'antd';

const LoadingScreen = () => {
	return (
		<Spin tip="Loading" size="large">
			<div style={{ width: '100vw', height: '100vh' }}></div>
		</Spin>
	);
};

export default LoadingScreen;
