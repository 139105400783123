import { AxiosRequestConfig } from 'axios';

type Props = {
	config: AxiosRequestConfig<unknown>;
};
const guestInterceptor = ({ config }: Props) => {
	const location = window.location;
	if (location.href.includes('/guest')) {
		const params = new URLSearchParams(location.search);
		const token = params.get('token');
		if (token) {
			config.headers = {
				...config.headers,
				'guest-token': token,
			};
		}
	}
	return config;
};

export default guestInterceptor;
