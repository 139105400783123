import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import {
	ADD_PROVIDER,
	ADD_PROVIDER_USER,
	DELETE_PROVIDER,
	EDIT_PROVIDER,
	EDIT_PROVIDER_USER,
	EDIT_PROVIDER_USER_ROLES,
	GET_PROVIDERS,
	GET_PROVIDERS_USERS,
	SEARCH_PROVIDERS,
	SELECT_PROVIDERS,
} from './types';

const loading =
	(ACTION) =>
	(state = false, { type }) => {
		switch (type) {
			case ACTION.PENDING:
				return true;
			case ACTION.SUCCESS:
			case ACTION.FAILURE:
				return false;
			default:
				return state;
		}
	};

const error =
	(ACTION) =>
	(state = { hasError: false, error: null }, { type, error }) => {
		if (type.startsWith(ACTION.prefix)) {
			return { hasError: type === ACTION.FAILURE, error };
		}
		return state;
	};

const get =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.SUCCESS: {
				let byId = payload.reduce(
					(providers, provider) => ({
						...providers,
						[provider.id]: provider,
					}),
					{}
				);
				let allIds = payload.map((provider) => provider.id);
				return {
					...state,
					byId,
					allIds,
				};
			}
			case ACTION.FAILURE: {
				let byId = {};
				let allIds = [];
				return {
					...state,
					byId,
					allIds,
				};
			}
			default:
				return state;
		}
	};

const search =
	(ACTION) =>
	(state = '', { type, payload }) => {
		switch (type) {
			case ACTION.SEARCH:
				return payload;
			default:
				return state;
		}
	};

const add =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.PENDING: {
				let { add } = state;
				return {
					...state,
					add: {
						...add,
						$new: payload,
					},
				};
			}
			case ACTION.SUCCESS: {
				let { byId, allIds, add } = state;

				byId = { ...byId, [payload.id]: payload };
				allIds = [...allIds, payload.id];

				return {
					...state,
					byId,
					allIds,
					add: Object.assign({}, add, { $new: {} }),
				};
			}
			default:
				return state;
		}
	};

const edit =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.START: {
				let {
					edit,
					edit: { byId },
				} = state;

				edit = {
					...edit,
					currentlyIdToEdit: payload.id,
					byId: {
						...byId,
						[payload.id]: payload,
					},
				};

				return {
					...state,
					edit,
				};
			}
			case ACTION.PENDING: {
				let { edit } = state;

				edit = {
					...edit,
					currentlyIdToEdit: payload.id,
					byId: {
						...edit.byId,
						[payload.id]: payload,
					},
				};
				return {
					...state,
					edit,
				};
			}
			case ACTION.CANCEL: {
				let { edit } = state;

				edit = {
					...edit,
					currentlyIdToEdit: 0,
				};

				return {
					...state,
					edit,
				};
			}
			case ACTION.SUCCESS: {
				let { byId, edit } = state;

				byId = {
					...byId,
					[payload.id]: {
						...JSON.parse(JSON.stringify(byId[payload.id])),
						...payload,
					},
				};
				let { [payload.id]: _, ...rest } = edit; // eslint-disable-line no-unused-vars
				edit = {
					...rest,
					currentlyIdToEdit: 0,
				};

				return {
					...state,
					byId,
					edit,
				};
			}
			default:
				return state;
		}
	};

const remove =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.PENDING:
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: payload.id,
					},
				};
			case ACTION.SUCCESS: {
				let { byId, allIds, selectedIds } = state;

				let { [payload.id]: _, ...rest } = byId; // eslint-disable-line no-unused-vars

				allIds = allIds.filter((providerId) => providerId !== payload.id);
				selectedIds = selectedIds.filter((providerId) => providerId !== payload.id);
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: 0,
					},
					byId: rest,
					allIds,
					selectedIds,
				};
			}
			case ACTION.FAILURE:
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: 0,
					},
				};
			default:
				return state;
		}
	};

const modalVisibility =
	(visible = [], hidden = []) =>
	(state = false, { type }) => {
		if (visible.includes(type)) return true;
		if (hidden.includes(type)) return false;
		return state;
	};

const rowSelection =
	(ACTION) =>
	(state = [], { type, payload }) => {
		if (type === ACTION.SELECT) return payload;
		return state;
	};

const editRoles =
	(ACTION) =>
	(state = {}, { type, payload }) => {
		switch (type) {
			case ACTION.START:
				return {
					...state,
					currentlyIdToEdit: payload.id,
					visible: true,
				};
			case ACTION.CANCEL:
			case ACTION.SUCCESS:
				return {
					...state,
					currentlyIdToEdit: 0,
					visible: false,
				};

			default:
				return state;
		}
	};

const providers = reduceReducers(
	combineReducers({
		loading: loading(GET_PROVIDERS),
		error: error(GET_PROVIDERS),
		selectedIds: rowSelection(SELECT_PROVIDERS),
		byId: (state = {}) => state,
		allIds: (state = []) => state,
		search: search(SEARCH_PROVIDERS),
		edit: combineReducers({
			loading: loading(EDIT_PROVIDER),
			error: error(EDIT_PROVIDER),
			visible: modalVisibility([EDIT_PROVIDER.START], [EDIT_PROVIDER.CANCEL, EDIT_PROVIDER.SUCCESS]),
			byId: (state = {}) => state,
			currentlyIdToEdit: (state = 0) => state,
		}),
		add: combineReducers({
			loading: loading(ADD_PROVIDER),
			error: error(ADD_PROVIDER),
			visible: modalVisibility([ADD_PROVIDER.START], [ADD_PROVIDER.CANCEL, ADD_PROVIDER.SUCCESS]),
			$new: (state = {}) => state,
		}),
		$delete: combineReducers({
			currentlyIdToDelete: (state = 0) => state,
			loading: loading(DELETE_PROVIDER),
		}),
	}),
	get(GET_PROVIDERS),
	add(ADD_PROVIDER),
	edit(EDIT_PROVIDER),
	remove(DELETE_PROVIDER)
);

const users = reduceReducers(
	combineReducers({
		byId: (state = {}) => state,
		allIds: (state = []) => state,
		edit: combineReducers({
			error: error(EDIT_PROVIDER_USER),
			byId: (state = {}) => state,
			currentlyIdToEdit: (state = 0) => state,
		}),
		add: combineReducers({
			loading: loading(ADD_PROVIDER),
			$new: (state = {}) => state,
		}),
	}),
	get(GET_PROVIDERS_USERS),
	add(ADD_PROVIDER_USER),
	edit(EDIT_PROVIDER_USER)
);

const roles = reduceReducers(
	combineReducers({
		loading: loading(EDIT_PROVIDER_USER_ROLES),
		error: error(EDIT_PROVIDER_USER_ROLES),
		visible: (state = false) => state, //modalVisibility([EDIT_PROVIDER_USER_ROLES.START], [EDIT_PROVIDER_USER_ROLES.CANCEL, EDIT_PROVIDER_USER_ROLES.SUCCESS]),
		currentlyIdToEdit: (state = 0) => state,
	}),
	editRoles(EDIT_PROVIDER_USER_ROLES)
);

export default combineReducers({
	providers,
	users,
	roles,
});
