import { asyncActionType } from '../../utilities/util';

export const GET_PROVIDERS = asyncActionType('GET_PROVIDERS');
export const ADD_PROVIDER = asyncActionType('ADD', 'PROVIDER');
export const EDIT_PROVIDER = asyncActionType('EDIT', 'PROVIDER');
export const DELETE_PROVIDER = asyncActionType('DELETE', 'PROVIDER');
export const SELECT_PROVIDERS = asyncActionType('SELECT', 'PROVIDERS');
export const SEARCH_PROVIDERS = asyncActionType('SEARCH', 'PROVIDERS');

export const GET_PROVIDERS_USERS = asyncActionType('GET', 'PROVIDERS_USERS');
export const ADD_PROVIDER_USER = asyncActionType('ADD', 'PROVIDER_USER');
export const EDIT_PROVIDER_USER = asyncActionType('EDIT', 'PROVIDER_USER');
export const EDIT_PROVIDER_USER_ROLES = asyncActionType('EDIT', 'PROVIDER_USER_ROLES');
