import { ActionType } from '../../types/actionType';
import { LOG_OUT } from '../auth/actionTypes';
import { FETCH_EVENTS } from '../events/actionTypes';
import {
	ADD_MODELS_PROFILES,
	CLEAN_REDUCER,
	DELETE_MODELS_PROFILES,
	EDIT_MODELS_PROFILES,
	FETCH_MODELS_PROFILES,
} from './actionTypes';

const initState = {
	profiles: [],
	events: [],
	commands: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case DELETE_MODELS_PROFILES:
		case EDIT_MODELS_PROFILES:
		case ADD_MODELS_PROFILES:
		case FETCH_MODELS_PROFILES:
			return {
				...state,
				profiles: action.profiles,
			};
		case ActionType.COMMANDS_FETCH_INFO:
			return {
				...state,
				commands: action.commands,
			};
		case FETCH_EVENTS:
			return {
				...state,
				events: action.events,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
