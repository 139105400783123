import axios from 'axios';
import { Sms } from '../entities/sms.entity';

const NOTIFICATION_ROUTE = '/v2/notification';

type Result<T> = {
	success?: T;
	error?: string;
};
type NotificationResponse = { success: { result: Record<string, string | unknown> } };
type ShortenLinkResponse = { url: string };

type NotificationServiceType = {
	sendNotification: (values: Sms) => Promise<Result<NotificationResponse>>;
	shortenLink: (value: string) => Promise<Result<ShortenLinkResponse>>;
};

const sendNotification = async (data: Sms): Promise<Result<NotificationResponse>> => {
	const response = await axios.post(`${NOTIFICATION_ROUTE}/sms`, data);

	return response.data;
};

const shortenLink = async (url: string): Promise<Result<ShortenLinkResponse>> => {
	const response = await axios.get<Result<ShortenLinkResponse>>(`${NOTIFICATION_ROUTE}/shorten-link/${url}`);

	return response.data;
};

const NotificationService: NotificationServiceType = {
	sendNotification,
	shortenLink,
};

export default NotificationService;
