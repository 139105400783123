import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export type DataSets = {
	labels: string[];
	data: number[];
};

type Props = {
	labelOnHover: string;
	dataSets: DataSets;
	backgroundColors: string[];
	labelPosition?: 'center' | 'top' | 'right' | 'bottom' | 'left' | 'chartArea';
};

Chart.register(ChartDataLabels);

const PieChart = ({ labelOnHover, dataSets, backgroundColors, labelPosition = 'top' }: Props) => {
	return (
		<Pie
			datasetIdKey="id"
			data={{
				labels: dataSets.labels,
				datasets: [
					{
						label: `  ${labelOnHover}`,
						data: dataSets.data,
						backgroundColor: backgroundColors,
					},
				],
			}}
			plugins={[
				{
					id: 'legendMargin',
					beforeInit: function (chart: any) {
						const fitValue = chart.legend.fit;
						chart.legend.fit = function fit() {
							fitValue.bind(chart.legend)();
							return (this.height += 30);
						};
					},
				},
			]}
			options={{
				layout: {
					padding: {
						left: 50,
						right: 50,
						top: 20,
						bottom: 0,
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
						position: labelPosition,
						align: 'start',
						fullSize: true,
						labels: {
							boxHeight: 10,
							font: {
								size: 12,
							},
							boxWidth: 20,
						},
					},
					datalabels: {
						display: true,
						color: '#737373',
						anchor: 'end',
						align: 'end',
						padding: 0,
						offset: 2,
						formatter: (value, ctx) => {
							const totalSum = ctx.dataset.data.reduce((accumulator, currentValue) => {
								return Number(accumulator) + Number(currentValue);
							}, 0);
							const percentage = (value / Number(totalSum)) * 100;
							return `${percentage.toFixed(1)}%`;
						},
					},
				},
			}}
		/>
	);
};

export default PieChart;
