export enum ActionType {
	CAMERA_FETCH_INFO = 'CAMERA_FETCH_INFO',
	COMMANDS_FETCH_INFO = 'COMMANDS_FETCH_INFO',
	MULTIMEDIA_OPEN_BY_DATE = 'MULTIMEDIA_OPEN_BY_DATE',
	MULTIMEDIA_OPEN_BY_TRACKING_HISTORY_ID = 'MULTIMEDIA_OPEN_BY_TRACKING_HISTORY_ID',
	MULTIMEDIA_CLOSE_MODAL = 'MULTIMEDIA_CLOSE_MODAL',
	MULTIMEDIA_UPDATE_UTC_DATE = 'MULTIMEDIA_UPDATE_UTC_DATE',
	MULTIMEDIA_CLEAR_REFHESH_DATA = 'MULTIMEDIA_CLEAR_REFHESH_DATA',
	MULTIMEDIA_CLEAR_CHANNEL_KEY = 'MULTIMEDIA_CLEAR_CHANNEL_KEY',
	MULTIMEDIA_REQUEST_LOADING = 'MULTIMEDIA_REQUEST_LOADING',
	UPDATE_DEVICE_EVENT = 'UPDATE_DEVICE_EVENT',
	GROUPS_FETCH_INFO = 'GROUPS_FETCH_INFO',
	GROUPS_ADD = 'GROUPS_ADD',
	GROUPS_EDIT = 'GROUPS_EDIT',
	GROUPS_DELETE = 'GROUPS_DELETE',
	GROUPS_FETCH_DEVICES = 'GROUPS_FETCH_DEVICES',
	GROUPS_FETCH_ACCOUNTS = 'GROUPS_FETCH_ACCOUNTS',
	GROUPS_UN_SHARE = 'GROUPS_UN_SHARE',
	LOADING_TRACE = 'LOADING_TRACE',
	GOOGLE_LOADED = 'GOOGLE_LOADED',
	CLEAN_REDUCER = 'CLEAN_REDUCER',
	LOG_OUT = 'LOG_OUT',
	USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE',
}

export interface IAction<T = undefined> {
	type: ActionType;
	payload?: T;
}

export interface CleanReducerAction extends IAction<undefined> {
	type: ActionType.CLEAN_REDUCER;
}
export interface LogOutAction extends IAction<undefined> {
	type: ActionType.LOG_OUT;
}
