import { ActionType } from '../../types/actionType';
import { Action } from './actions';

export type MultimediaModal = {
	open: boolean;
	utcDate?: Date;
	trackingHistoryId?: string;
	deviceId?: number;
	utcOffsetMinutes?: number;
	shouldRefreshData: boolean;
	channelKey?: string;
};

type State = {
	googleLoaded: boolean;
	event: boolean;
	loadingTraceState: boolean;
	multimediaModal: MultimediaModal;
};

const initialState: State = {
	googleLoaded: false,
	event: false,
	loadingTraceState: false,
	multimediaModal: {
		open: false,
		utcDate: undefined,
		trackingHistoryId: undefined,
		deviceId: undefined,
		utcOffsetMinutes: undefined,
		shouldRefreshData: false,
		channelKey: undefined,
	},
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.UPDATE_DEVICE_EVENT:
			return { ...state, event: !state.event };
		case ActionType.LOADING_TRACE:
			return {
				...state,
				loadingTraceState: !state.loadingTraceState,
			};
		case ActionType.GOOGLE_LOADED:
			return {
				...state,
				googleLoaded: true,
				event: false,
			};
		case ActionType.MULTIMEDIA_OPEN_BY_DATE:
		case ActionType.MULTIMEDIA_OPEN_BY_TRACKING_HISTORY_ID: {
			if (action.payload) {
				const { shouldRefreshData = false, ...rest } = action.payload;
				if (state.multimediaModal.open && shouldRefreshData) {
					return { ...state, multimediaModal: { ...rest, shouldRefreshData: true } };
				}
				return { ...state, multimediaModal: { ...rest, shouldRefreshData: false } };
			}
			return state;
		}
		case ActionType.MULTIMEDIA_UPDATE_UTC_DATE:
			return {
				...state,
				multimediaModal: { ...state.multimediaModal, utcDate: action.payload, trackingHistoryId: undefined },
			};
		case ActionType.MULTIMEDIA_CLEAR_REFHESH_DATA:
			return {
				...state,
				multimediaModal: { ...state.multimediaModal, shouldRefreshData: action.payload as boolean },
			};
		case ActionType.MULTIMEDIA_CLEAR_CHANNEL_KEY:
			return { ...state, multimediaModal: { ...state.multimediaModal, channelKey: undefined } };
		case ActionType.MULTIMEDIA_CLOSE_MODAL:
			return { ...state, multimediaModal: { ...initialState.multimediaModal } };
		case ActionType.CLEAN_REDUCER:
		case ActionType.LOG_OUT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
