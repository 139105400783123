import { ActionType } from '../../types/actionType';
import { CommandDto } from '../modelProfile/modelProfile.dto';
import { Action } from './actions';

export type State = {
	list: CommandDto[];
};
const initialState: State = {
	list: [],
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.COMMANDS_FETCH_INFO:
			return {
				...state,
				list: action.payload as CommandDto[],
			};
		case ActionType.CLEAN_REDUCER:
		case ActionType.LOG_OUT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
