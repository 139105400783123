import { LOG_OUT } from '../auth/actionTypes';
import { FILL_CLIENTS } from '../clients/actionTypes';
import { CLEAR_BACKUP, DO_IMPERSONATE, FILL_ACCOUNTS, FILL_COMPANIES, IMPERSONATE_SUCCESS } from './actionTypes';

const initState = {
	auth: {},
	configuration: {},
	authToken: '',
	isImpersonating: false,
	confirmLoading: false,
	companies: [],
	clients: [],
	accounts: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FILL_COMPANIES:
			return {
				...state,
				companies: action.companies,
			};
		case FILL_CLIENTS:
			return {
				...state,
				clients: action.clients,
			};
		case FILL_ACCOUNTS:
			return {
				...state,
				accounts: action.accounts,
			};
		case DO_IMPERSONATE:
			return {
				...state,
				confirmLoading: true,
			};
		case IMPERSONATE_SUCCESS:
			return {
				...state,
				auth: action.backUp.auth,
				configuration: action.backUp.configuration,
				authToken: action.backUp.authToken,
				isImpersonating: true,
			};
		case CLEAR_BACKUP:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
