import AuthServiceInstance from './auth.service';
import CompanyServiceInstance from './company.service';
import NotificationServiceInstance from './notification.service';
import RouteUserServiceInstance from './routeUser.service';
import TaskServiceInstance from './task.service';

export const RouteUserService = RouteUserServiceInstance;
export const TaskService = TaskServiceInstance;
export const AuthService = AuthServiceInstance;
export const CompanyService = CompanyServiceInstance;
export const NotificationService = NotificationServiceInstance;
