import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Button, Col, Divider, Empty, Row } from 'antd';
import { OutdatedTaskAndTotal } from '../../../interfaces';

type Props = {
	title: string;
	daysList: OutdatedTaskAndTotal;
	className: string;
	onViewMore: () => void;
	messageOnEmpty?: string;
};

const PendingTasksDetail = ({ title, daysList, className, onViewMore, messageOnEmpty = '' }: Props) => {
	const RenderDaysList = useMemo(
		() =>
			daysList.data.map(({ dueDate, count }) => (
				<Row key={new Date() + dueDate.toString()} gutter={[8, 8]} justify="space-between">
					<Col span="16">{dueDate.toString()}</Col>
					<Col span="4" className="total">
						{count}
					</Col>
				</Row>
			)),
		[daysList]
	);

	return (
		<div className="pending-card-detail overview-card">
			<Row className="overview-title">{title}</Row>
			{daysList.total === 0 ? (
				<Empty className="empty" description={messageOnEmpty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
			) : (
				<>
					<Row className={`count ${className}`}>{daysList.total}</Row>
					<div className="list-container">{RenderDaysList}</div>
				</>
			)}
			<Divider />
			<Button onClick={onViewMore}>
				<FormattedMessage {...translate('viewMore')} />
			</Button>
		</div>
	);
};

export default PendingTasksDetail;
