import { LOG_OUT } from '../auth/actionTypes';
import { FETCH_SESSIONS } from './actionTypes';

const initState = {
	sessions: [],
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_SESSIONS:
			return {
				...state,
				sessions: action.sessions,
				count: action.count,
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
