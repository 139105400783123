export const version = 1;
const migrations = {
	0: (state) => state,
	1: (state) => {
		if (!state) {
			return state;
		}
		return {
			...state,
			group: {
				...state.group,
				groups: [],
			},
		};
	},
};

export default migrations;
