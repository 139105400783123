import { useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row, Typography } from 'antd';
import Lottie from 'lottie-react';
import CustomerSuport from '@/images/CustomerSuport.json';
import { amazonImageUrl } from '@/utilities/util';

const ContactSupport = (): JSX.Element => {
	const [searchParams] = useSearchParams();

	const name = searchParams.get('name');
	const imageUrl = amazonImageUrl({ folder: 'logos', name: 'optimus' });
	const logo = <img src={imageUrl} alt="Logo" className="imageLogo" style={{ paddingTop: '10px' }} />;
	return (
		<div className="view">
			<Row className="customer-support-container" justify="space-around" align="middle">
				<Col xs={24} md={16} lg={12} xl={8} xxl={6}>
					<Card cover={logo} bordered={false}>
						<Row justify="space-around" align="middle" gutter={[0, 15]}>
							<Col span={24}>
								<Typography.Text className="customer-support-container-title">
									Hello {name ?? 'Customer'}
								</Typography.Text>
							</Col>
							<Col span={24}>
								<Lottie
									className="customer-support-container-lottie"
									animationData={CustomerSuport}
									loop={true}
									autoPlay={true}
								/>
							</Col>
							<Col span={24}>
								<Typography.Text className="customer-support-container-disclaimer">
									We could not migrate your account. Please contact our
									<span> Customer Service</span>, or wait for us to call you.
								</Typography.Text>
							</Col>
							<Col span={24}>
								<Button
									className="customer-support-container-proceed"
									size="large"
									type="primary"
									shape="round"
									onClick={() => window.location.replace('https://optimustracker.com/pages/contact')}
								>
									Contact
								</Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ContactSupport;
