import {
	CLEAR_SESSION,
	FETCH_ADDRESS,
	FETCH_ALL_CLIENTS,
	FETCH_ALL_DEVICES,
	FETCH_DATA,
	SET_REQUEST_COUNTER,
} from './actionsTypes';

const initState = {
	data: [],
	devices: [],
	addresses: [],
	commands: [],
	user: [],
	clients: [],
	showTutorial: false,
	requestCounter: { total: 0, completed: 0 },
};

const reports = (state = initState, action) => {
	switch (action.type) {
		case FETCH_DATA:
			return {
				...state,
				addresses: [],
				data: action.data,
			};
		case FETCH_ALL_DEVICES:
			return {
				...state,
				devices: action.devices,
			};
		case FETCH_ADDRESS: {
			let addresses = [...state.addresses];
			let index = action.payload.index.toString();
			if (!addresses[index]) addresses[index] = {};
			addresses[index] = { ...addresses[index] };
			addresses[index][action.payload.type] = action.payload.address;
			return {
				...state,
				addresses,
			};
		}
		case FETCH_ALL_CLIENTS:
			return {
				...state,
				clients: action.clients,
			};
		// case '@@router/LOCATION_CHANGE':
		//     return action.payload.pathname.indexOf("/reports") >= 0 ? initState : state
		case CLEAR_SESSION:
			return initState;
		case SET_REQUEST_COUNTER:
			return {
				...state,
				requestCounter: action.payload,
			};
		default:
			return state;
	}
};

export default reports;
