class Unit {
	constructor({ conversion = 1, units = ['', ''], decimal = 0, convert = false }) {
		var _units = units;
		var _conversion = conversion;
		var _decimal = decimal;
		var _convert = convert;

		this.getUnits = () => _units;
		this.setUnits = (u) => (_units = u);

		this.getConversion = () => _conversion;
		this.setConversion = (conv) => (_conversion = conv);

		this.getDecimal = () => _decimal;
		this.setDecimal = (dec) => (_decimal = dec);

		this.getConvert = () => _convert;
		this.setConvert = (con) => (_convert = con);
	}

	reverse = () => {
		return new Unit({
			decimal: this.getDecimal(),
			conversion: 1 / this.getConversion(),
			units: [...this.getUnits()].reverse(),
			convert: true,
		});
	};

	convertValue = (value, convert) => +(convert ? value * this.getConversion() : value);

	toString = (value = 0, { convert = this.getConvert(), unit = true, decimal = this.getDecimal() } = {}) => {
		value = this.convertValue(value, convert).toFixed(decimal);
		return convert
			? `${value}${unit ? ' ' + this.getUnits()[1] : ''}`
			: `${value}${unit ? ' ' + this.getUnits()[0] : ''}`;
	};

	getCurrentUnit = () => (this.getConvert() ? this.getUnits()[1] : this.getUnits()[0]);

	format = (value = 0, { convert = this.getConvert(), decimal = this.getDecimal() } = {}) =>
		+this.convertValue(value, convert).toFixed(decimal);
}

export default Unit;
