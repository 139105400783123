import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_POINT_OF_INTEREST,
	CLEAN_REDUCER,
	EDIT_POINT_OF_INTEREST,
	FETCH_POI_ICONS,
	FETCH_POINTS,
	FETCH_TAGS,
	UPLOAD_POINTS_OF_INTEREST,
} from './actionTypes';

const initState = {
	points: [],
	tags: [],
	icons: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_POINT_OF_INTEREST:
		case EDIT_POINT_OF_INTEREST:
		case UPLOAD_POINTS_OF_INTEREST:
		case FETCH_POINTS: {
			return {
				...state,
				points: action.points,
			};
		}
		case FETCH_POI_ICONS:
			return {
				...state,
				icons: action.icons,
			};
		case FETCH_TAGS:
			return {
				...state,
				tags: action.tags,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
