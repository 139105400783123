import { LOG_OUT } from '../auth/actionTypes';
import { CLEAN_REDUCER, SELECTED_TRAIL, SHARED_DEVICES_EXTERNAL } from './actionTypes';

const initState = {
	sharedDevicesExternal: [],
	selectedTrail: undefined,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SHARED_DEVICES_EXTERNAL:
			return {
				...state,
				sharedDevicesExternal: action.data,
			};
		case SELECTED_TRAIL:
			return {
				...state,
				selectedTrail: action.selectedTrail,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
