import moment from 'moment';
import { UnitSystemEnum } from '../../constants/global';
import TemperatureUnit from './/temperatureUnit';
import distanceUnit from './distanceUnit';
import EfficiencyUnit from './efficiencyUnit';
import Unit from './unit';

class UnitSystem {
	constructor() {
		this.units = {
			Mi: 0.621371,
			Km: 1000.0,
			'Km/h': 1,
			MPH: 0.621371,
			L: 1,
			Gal: 0.264172,
			m: 1,
			ft: 3.28084,
			in: 0.393701,
			cm: 1,
			'Km/L': 1,
			MPG: 2.35214583,
			'Km/G': 3.7854,
			MPL: 0.6214,
			Celcius: 1.8,
			RPM: 1,
		};

		this.options = {
			decimal: 2,
		};
	}

	setConfigurations = (Config) => {
		this.configuration = Config;
		this.initialize();
	};

	getEfficiencyConversion = () => {
		if (
			this.configuration.distance === UnitSystemEnum.KILOMETER &&
			this.configuration.volume === UnitSystemEnum.GALLON
		)
			return this.units['Km/G'];
		else if (
			this.configuration.distance === UnitSystemEnum.MILE &&
			this.configuration.volume === UnitSystemEnum.LITER
		)
			return this.units.MPL;
	};

	initialize = () => {
		this.monthYearFormat = 'MM/YYYY';

		this.hourMinuteFormat = 'HH:mm';
		this.hourMinuteSeccondsFormat = 'hh:mm:ss A';

		this.standardTime = this.configuration.time === UnitSystemEnum.TIME_12_HRS ? 'hh:mm A' : this.hourMinuteFormat;

		this.timeFormat = {
			timestamp: 'h [hrs], m [min]',
			timestampWithSecondsHumanize: 'h [hrs], m [min], s [sec]',
			timestampWithSeconds: 'hh:mm:ss',
			time: this.standardTime,
			timeWithSeconds: this.configuration.time,
		};

		this.dateTimeFormat = `${this.configuration.date} ${this.standardTime}`;

		this.completeDateTimeFormat = `${this.configuration.date} ${this.configuration.time}`;

		this.dateTimeHumanize =
			this.configuration.date === UnitSystemEnum.DATE_FORMAT_METRIC
				? `DD MMMM YYYY hh:mm A`
				: 'MMMM DD YYYY HH:mm ';

		this.speed = new Unit({
			decimal: this.options.decimal,
			conversion: this.units.MPH,
			units: [UnitSystemEnum.KILOMETER_PER_HOUR, UnitSystemEnum.MILE_PER_HOUR],
			convert: this.configuration?.speed !== UnitSystemEnum.KILOMETER_PER_HOUR,
		});

		this.rpm = new Unit({
			decimal: 0,
			units: [UnitSystemEnum.RPM],
		});

		this.shortDistance = new Unit({
			decimal: this.options.decimal,
			conversion: this.units.ft,
			units: ['m', 'ft'],
			convert: this.configuration?.distance !== UnitSystemEnum.KILOMETER,
		});

		this.shortDistance2 = new Unit({
			decimal: this.options.decimal,
			conversion: this.units.in,
			units: ['cm', 'in'],
			convert: this.configuration?.distance !== UnitSystemEnum.KILOMETER,
		});

		this.volume = new Unit({
			decimal: this.options.decimal,
			conversion: this.units.Gal,
			units: [UnitSystemEnum.LITER, UnitSystemEnum.GALLON],
			convert: this.configuration?.volume !== UnitSystemEnum.LITER,
		});

		this.efficiency = this.customEfficiency
			? new EfficiencyUnit({
					decimal: this.options.decimal,
					units: [this.configuration.distance, this.configuration.volume],
					conversion: this.getEfficiencyConversion(),
			  })
			: new Unit({
					decimal: this.options.decimal,
					conversion: this.units.MPG,
					units: [UnitSystemEnum.KILOMETER_PER_LITER, UnitSystemEnum.MILE_PER_GALLON],
					convert: this.configuration?.efficiency !== UnitSystemEnum.KILOMETER_PER_LITER,
			  });

		this.temperature = new TemperatureUnit({
			decimal: 4,
			conversion: this.units.Celcius,
			units: [UnitSystemEnum.CELSIUS, UnitSystemEnum.FAHRENHEIT],
			convert: this.configuration?.temperature !== UnitSystemEnum.CELSIUS,
			ConstantValue: 32,
		});

		this.distance = new distanceUnit({
			decimal: 4,
			conversion: this.units.Mi,
			units: [UnitSystemEnum.KILOMETER, UnitSystemEnum.MILE],
			convert: this.configuration?.distance !== UnitSystemEnum.KILOMETER,
		});
	};

	get customEfficiency() {
		return this.configuration.efficiency === UnitSystemEnum.CUSTOM_EFFICIENCY;
	}

	getPercentText = (value = 0, decimal = this.options.decimal) => `${(value * 100).toFixed(decimal)} %`;
	getDegreesText = (value = 0) => `${value.toFixed(0)}°`;

	getMetricSpeed = (value = 0, decimal = this.options.decimal) => {
		if (this.configuration.speed === UnitSystemEnum.KILOMETER_PER_HOUR) return +value.toFixed(decimal);
		else return +(value / this.units.MPH).toFixed(decimal);
	};

	convertVoltage = (millivolts) => millivolts / 1000;
	getDate = (value) => value.format(this.getDateFormat());
	getTime = (value) => value.format(this.timeFormat.time);
	getTimeWithSeconds = (value) => value.format(this.timeFormat.timeWithSeconds);

	getTimestamp = (milliseconds) =>
		moment.duration(milliseconds).format(this.timeFormat.timestamp, { trim: false, trunc: true });
	getTimestampWithSecondsHumanize = (milliseconds) =>
		moment.duration(milliseconds).format(this.timeFormat.timestampWithSecondsHumanize, {
			trim: false,
			trunc: true,
		});
	getTimestampWithSeconds = (milliseconds) =>
		moment.duration(milliseconds).format(this.timeFormat.timestampWithSeconds, {
			trim: false,
			trunc: true,
		});

	getDateFormat = () => this.configuration.date;

	getDateTimeFormat = () => this.dateTimeFormat;

	getCompleteDateTimeFormat = () => this.completeDateTimeFormat;

	getDateTimeHumanizeFormat = () => this.dateTimeHumanize;

	getDateWithZeroTime = (time) => moment.utc(time).startOf('date').format('YYYY-MM-DDTHH:mm:ss');

	getMonthYearFormat = () => this.monthYearFormat;

	getHourMinuteFormat = () => this.hourMinuteFormat;

	getTimeFormat = () => this.standardTime;

	getDayMonthYearFomat = () =>
		this.configuration.date === UnitSystemEnum.DATE_FORMAT_METRIC ? `DD MMMM YYYY` : 'MMMM DD YYYY';
}

export default UnitSystem;
