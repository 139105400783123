import { IMPERSONATE, LOG_OUT } from '../auth/actionTypes';
import { DELETE_POSITION, FETCH_DEVICES_POSITIONS } from './actionTypes';

const initState = {
	devicePositions: [],
};

const reports = (state = initState, action) => {
	switch (action.type) {
		case DELETE_POSITION:
		case FETCH_DEVICES_POSITIONS:
			return {
				...state,
				devicePositions: action.positions,
			};
		case IMPERSONATE:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reports;
