import { Button } from 'antd';

type Props = {
	label: string;
	onClick: () => void;
	icon: JSX.Element;
	className?: string;
};
const QuickAccessButton = ({ label, onClick, className, icon }: Props) => {
	return (
		<Button type="text" block onClick={onClick} className={className}>
			<div className="quick-access-button">
				<div className="qa-icon">{icon}</div>
				<div className="qa-label">{label}</div>
			</div>
		</Button>
	);
};

export default QuickAccessButton;
