import React from 'react';
import compile from 'string-template/compile';
import iconHash from './src/iconHash';

export default (() => {
	let icon = {
		...iconHash,
	};

	icon.getIconInfo = (id = 1) => {
		if (icon[id] === undefined || icon[id].label === undefined) return icon['defaultEvent'].label;
		return icon[id].label;
	};

	icon.getIcon = (id = 1, azimuth = 0) => {
		return {
			...icon[id].map,
			rotation: azimuth,
		};
	};

	icon.getIconSVG = (id = 1, myclass = '') => {
		let svg = (
			<svg className={`icon ${icon[id].label} ${myclass}`}>
				<use xlinkHref={`/styles/svg-demo/symbol-defs.svg#${icon[id].label}`} />
			</svg>
		);
		return svg;
	};

	icon.getIconSpan = (id = 1, myclass = '') => {
		return `<span class="${icon.getIconInfo(id)} ${myclass}"></span>`;
	};

	icon.getEventIconSvg = (eventId) => {
		let key = eventId ? `event${eventId}` : 'defaultEvent';
		let icon = iconHash[key] && iconHash[key].svg ? iconHash[key].svg : iconHash['defaultEvent'].svg;
		let compiler = compile(icon.svg);
		let svg = compiler({});
		let link = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
		return link;
	};

	icon.svgToIcon = ({ id = '1', azimuth = 0, color = '#fafafafa', siren = false, colors }) => {
		let vehicle = icon[id];
		if (vehicle === undefined) vehicle = icon['defaultEvent'];
		let svg = vehicle.svg;
		const orientationX = azimuth > 180 && azimuth < 360 ? -1 : 1;
		const orientationY = 1;
		let compiler = compile(svg.svg);

		const mergedColors = colors ? { ...vehicle.svg.colors, ...colors } : { color, ...vehicle.svg.colors };

		svg = compiler({
			...mergedColors,
			orientationX,
			orientationY,
			azimuth: azimuth > 180 && azimuth < 360 ? azimuth * -1 : azimuth,
			sirena: siren ? 'animation:sirena-active 0.35s infinite;' : '',
		});

		return {
			url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
			anchor: vehicle.svg.anchor,
			scaledSize: {
				width: vehicle.svg.width,
				height: vehicle.svg.height,
			},
		};
	};
	icon.eventSvgToIcon = ({ id = 'defaultEvent' }) => {
		let event = icon[id];
		if (event === undefined) event = icon['defaultEvent'];
		let svg = event.svg;
		let compiler = compile(svg.svg);
		svg = compiler({});

		return {
			url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
			anchor: event.svg.anchor,
			scaledSize: { width: event.svg.width, height: event.svg.height },
		};
	};
	icon.svgToIconByName = ({ name = 'icon' }) => {
		let newIcon = icon[name];
		if (newIcon === undefined) newIcon = icon['defaultEvent'];
		let svg = newIcon.svg;
		let compiler = compile(svg.svg);
		svg = compiler({});

		return {
			url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
			anchor: newIcon.svg.anchor,
			scaledSize: { width: newIcon.svg.width, height: newIcon.svg.height },
		};
	};
	return icon;
})();
