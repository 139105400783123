export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SESSION_REVOKED = 'session-revoked';
export const RENEW_SESSION = 'renew-session';

export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const FETCH_CLIENT_COMPANY = 'FETCH_CLIENT_COMPANY';
export const FETCH_USER_CLIENT = 'FETCH_USER_CLIENT';
export const FETCH_UNITSYSTEM = 'FETCH_UNITSYSTEM';
export const INIT_MAP_CONFIGURATION = 'INIT_MAP_CONFIGURATION';
export const IMPERSONATE = 'IMPERSONATE';
export const USER_FORCE_PASSWORD = 'USER_FORCE_PASSWORD';
export const UPDATE_USER_DASHBOARD_CONFIG = 'UPDATE_USER_DASHBOARD_CONFIG';
export const PROFILE_IMAGE = 'PROFILE_IMAGE';
export const SET_UNIT_SYSTEM = 'SET_UNIT_SYSTEM';

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION';
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN';
