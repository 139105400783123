import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import {
	ADD_CLIENT_ADMIN,
	ADD_CLIENT_ADMIN_USER,
	DELETE_CLIENT_ADMIN,
	EDIT_CLIENT_ADMIN,
	EDIT_CLIENT_ADMIN_USER,
	EDIT_CLIENT_ADMIN_USER_ROLES,
	GET_CLIENT_ADMINS,
	GET_CLIENT_ADMINS_USERS,
	SEARCH_CLIENT_ADMINS,
	SELECT_CLIENT_ADMINS,
} from './types';

const loading =
	(ACTION) =>
	(state = false, { type }) => {
		switch (type) {
			case ACTION.PENDING:
				return true;
			case ACTION.SUCCESS:
			case ACTION.FAILURE:
				return false;
			default:
				return state;
		}
	};

const error =
	(ACTION) =>
	(state = { hasError: false, error: null }, { type, error }) => {
		if (type.startsWith(ACTION.prefix)) {
			return { hasError: type === ACTION.FAILURE, error };
		}
		return state;
	};

const get =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.SUCCESS: {
				let byId = payload.reduce(
					(clientAdmins, clientAdmin) => ({
						...clientAdmins,
						[clientAdmin.id]: clientAdmin,
					}),
					{}
				);
				let allIds = payload.map((clientAdmin) => clientAdmin.id);
				return {
					...state,
					byId,
					allIds,
				};
			}
			case ACTION.FAILURE: {
				let byId = {};
				let allIds = [];
				return {
					...state,
					byId,
					allIds,
				};
			}
			default:
				return state;
		}
	};

const search =
	(ACTION) =>
	(state = '', { type, payload }) => {
		switch (type) {
			case ACTION.SEARCH:
				return payload;
			default:
				return state;
		}
	};

const add =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.PENDING: {
				let { add } = state;
				return {
					...state,
					add: {
						...add,
						$new: payload,
					},
				};
			}
			case ACTION.SUCCESS: {
				let { byId, allIds, add } = state;

				byId = { ...byId, [payload.id]: payload };
				allIds = [...allIds, payload.id];

				return {
					...state,
					byId,
					allIds,
					add: Object.assign({}, add, { $new: {} }),
					loading: false,
				};
			}
			case ACTION.FAILURE: {
				let { add } = state;
				return {
					...state,
					add: Object.assign({}, add, { $new: {} }),
					loading: false,
				};
			}
			default:
				return state;
		}
	};

const edit =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.START: {
				let {
					edit,
					edit: { byId },
				} = state;

				edit = {
					...edit,
					currentlyIdToEdit: payload.id,
					byId: {
						...byId,
						[payload.id]: payload,
					},
				};

				return {
					...state,
					edit,
				};
			}
			case ACTION.PENDING: {
				let { edit } = state;

				edit = {
					...edit,
					currentlyIdToEdit: payload.id,
					byId: {
						...edit.byId,
						[payload.id]: payload,
					},
				};
				return {
					...state,
					edit,
				};
			}
			case ACTION.CANCEL: {
				let { edit } = state;

				edit = {
					...edit,
					currentlyIdToEdit: 0,
				};

				return {
					...state,
					edit,
				};
			}
			case ACTION.SUCCESS: {
				let { byId, edit } = state;

				byId = {
					...byId,
					[payload.id]: {
						...JSON.parse(JSON.stringify(byId[payload.id])),
						...payload,
					},
				};
				let { [payload.id]: _, ...rest } = edit; // eslint-disable-line no-unused-vars
				edit = {
					...rest,
					currentlyIdToEdit: 0,
				};

				return {
					...state,
					byId,
					edit,
				};
			}
			default:
				return state;
		}
	};

const remove =
	(ACTION) =>
	(state, { type, payload }) => {
		switch (type) {
			case ACTION.PENDING:
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: payload.id,
					},
				};
			case ACTION.SUCCESS: {
				let { byId, allIds, selectedIds } = state;

				let { [payload.id]: _, ...rest } = byId; // eslint-disable-line no-unused-vars

				allIds = allIds.filter((clientAdminId) => clientAdminId !== payload.id);
				selectedIds = selectedIds.filter((clientAdminId) => clientAdminId !== payload.id);
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: 0,
					},
					byId: rest,
					allIds,
					selectedIds,
				};
			}
			case ACTION.FAILURE:
				return {
					...state,
					$delete: {
						...state.$delete,
						currentlyIdToDelete: 0,
					},
				};
			default:
				return state;
		}
	};

const modalVisibility =
	(visible = [], hidden = []) =>
	(state = false, { type }) => {
		if (visible.includes(type)) return true;
		if (hidden.includes(type)) return false;
		return state;
	};

const rowSelection =
	(ACTION) =>
	(state = [], { type, payload }) => {
		if (type === ACTION.SELECT) return payload;
		return state;
	};

const editRoles =
	(ACTION) =>
	(state = {}, { type, payload }) => {
		switch (type) {
			case ACTION.START:
				return {
					...state,
					currentlyIdToEdit: payload.id,
					visible: true,
				};
			case ACTION.CANCEL:
			case ACTION.SUCCESS:
				return {
					...state,
					currentlyIdToEdit: 0,
					visible: false,
				};

			default:
				return state;
		}
	};

const clientAdmins = reduceReducers(
	combineReducers({
		loading: loading(GET_CLIENT_ADMINS),
		error: error(GET_CLIENT_ADMINS),
		selectedIds: rowSelection(SELECT_CLIENT_ADMINS),
		byId: (state = {}) => state,
		allIds: (state = []) => state,
		search: search(SEARCH_CLIENT_ADMINS),
		edit: combineReducers({
			loading: loading(EDIT_CLIENT_ADMIN),
			error: error(EDIT_CLIENT_ADMIN),
			visible: modalVisibility([EDIT_CLIENT_ADMIN.START], [EDIT_CLIENT_ADMIN.CANCEL, EDIT_CLIENT_ADMIN.SUCCESS]),
			byId: (state = {}) => state,
			currentlyIdToEdit: (state = 0) => state,
		}),
		add: combineReducers({
			loading: loading(ADD_CLIENT_ADMIN),
			error: error(ADD_CLIENT_ADMIN),
			visible: modalVisibility(
				[ADD_CLIENT_ADMIN.START],
				[ADD_CLIENT_ADMIN.CANCEL, ADD_CLIENT_ADMIN.SUCCESS, ADD_CLIENT_ADMIN.FAILURE]
			),
			$new: (state = {}) => state,
		}),
		$delete: combineReducers({
			currentlyIdToDelete: (state = 0) => state,
			loading: loading(DELETE_CLIENT_ADMIN),
		}),
	}),
	get(GET_CLIENT_ADMINS),
	add(ADD_CLIENT_ADMIN),
	edit(EDIT_CLIENT_ADMIN),
	remove(DELETE_CLIENT_ADMIN)
);

const users = reduceReducers(
	combineReducers({
		byId: (state = {}) => state,
		allIds: (state = []) => state,
		edit: combineReducers({
			error: error(EDIT_CLIENT_ADMIN_USER),
			byId: (state = {}) => state,
			currentlyIdToEdit: (state = 0) => state,
		}),
		add: combineReducers({
			loading: loading(ADD_CLIENT_ADMIN),
			$new: (state = {}) => state,
		}),
	}),
	get(GET_CLIENT_ADMINS_USERS),
	add(ADD_CLIENT_ADMIN_USER),
	edit(EDIT_CLIENT_ADMIN_USER)
);

const roles = reduceReducers(
	combineReducers({
		loading: loading(EDIT_CLIENT_ADMIN_USER_ROLES),
		error: error(EDIT_CLIENT_ADMIN_USER_ROLES),
		visible: (state = false) => state, //modalVisibility([EDIT_CLIENT_ADMIN_USER_ROLES.START], [EDIT_CLIENT_ADMIN_USER_ROLES.CANCEL, EDIT_CLIENT_ADMIN_USER_ROLES.SUCCESS]),
		currentlyIdToEdit: (state = 0) => state,
	}),
	editRoles(EDIT_CLIENT_ADMIN_USER_ROLES)
);

export default combineReducers({
	clientAdmins,
	users,
	roles,
});
