import axios from 'axios';
import qs from 'qs';
import { MAP_TYPE } from '../../constants/global';
import { AuthService } from '../../OptimusRoutes/services';
import mqtt from '../rabbitmq';
import { FETCH_USER_ROLES, LOG_IN, LOG_OUT, UPDATE_REFRESH_TOKEN, USER_FORCE_PASSWORD } from './actionTypes';

export const login = async (credentials) => {
	return await AuthService.Login(credentials);
};

export const setUserData = (data) => {
	const {
		account: {
			client: { company, ...client },
			mapConfigurations,
			speedRangeConfiguration,
			...user
		},
		accessToken: id,
		refreshToken,
		expiresIn,
		roles,
	} = data;
	return {
		type: LOG_IN,
		user: user,
		token: id,
		company,
		client,
		userRoles: roles,
		config: InitMapConfigurations(mapConfigurations),
		speedRangesConfiguration: speedRangeConfiguration,
		refreshToken,
		expiresIn,
	};
};

export const fetchUserRoles = (userRoles) => ({
	type: FETCH_USER_ROLES,
	userRoles,
});

export const GetCompanyAndClient = (ClientId) => async () => {
	if (!ClientId) return;

	let filter = {
		include: ['company'],
	};
	try {
		let response = await axios.get(`/Clients/${ClientId}/?filter=${encodeURIComponent(JSON.stringify(filter))}`);

		return {
			company: response.data.company,
			client: response.data,
		};
	} catch (error) {
		throw error;
	}
};

const MapTypeKeys = Object.keys(MAP_TYPE);

export const InitMapConfigurations = (config) => {
	if (!!config) {
		return {
			traffic: config.showTraffic,
			showTrace: config.showTrace,
			centerOnSelect: config.centerOnSelect,
			centerOnUpdate: config.centerOnUpdate,
			traceColor: config.traceColor,
			traceBySpeedIsActive: config.traceBySpeedIsActive,
			speedRangesConfiguration: config.speedRangesConfiguration,
			movementColor: config.vehicleOnMovementColor,
			parkingColor: config.vehicleParkedColor,
			idleColor: config.vehicleIdleColor,
			clusterDevices: config.clusterDevices,
			mapType: MAP_TYPE[MapTypeKeys[config.mapType]],
		};
	}

	return {};
};

export const LogOut = (refreshToken) => async (dispatch) => {
	await AuthService.Logout(refreshToken);
	localStorage.clear();
	return dispatch(cleanAuthInformation());
};

export const ResetRequest = (email) => () => {
	let data = {
		email: email,
	};

	return axios.post(`/Accounts/reset`, data);
};

export const ResetUserPassword =
	({ password, access_token }) =>
	(dispatch, getState) => {
		const state = getState();
		const options = {
			newPassword: password,
		};

		return axios.post(`/Accounts/reset-password?access_token=${access_token}`, options).then(() => {
			if (state.auth.isAuthenticated) {
				const user = {
					updatePassword: false,
				};
				return dispatch({ type: USER_FORCE_PASSWORD, user: user });
			}
		});
	};

export const cleanAuthInformation = () => {
	axios.defaults.headers.common['Authorization'] = null;
	mqtt.end();
	delete window.unitSystem;
	delete window.main;
	return { type: LOG_OUT };
};

export const ConfirmEmail = async ({ uid, token }) => {
	const params = { uid, token };
	return await axios.get(`/Accounts/confirm?${qs.stringify(params)}`);
};

export const setNewTokens = (accessToken, refreshToken, expiresIn, roles) => {
	return {
		type: UPDATE_REFRESH_TOKEN,
		token: accessToken,
		refreshToken,
		expiresIn,
		roles,
	};
};
