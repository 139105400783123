import { CLEAN_REDUCER } from '../../constants/actionTypes';
import { LOG_OUT } from '../auth/actionTypes';
import { ADD_SIMS_SUCCESS, FETCH_SIMS, FETCH_SIMS_CARRIERS, FETCH_SIMS_COUNT } from './actionTypes';

const initState = {
	list: [],
	editable: {},
	count: 0,
	carriers: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_SIMS_COUNT:
			return {
				...state,
				count: action.count,
			};
		case ADD_SIMS_SUCCESS: {
			return {
				...state,
				list: action.payload,
				count: action.count,
			};
		}
		case FETCH_SIMS:
			return {
				...state,
				list: action.payload,
			};
		case FETCH_SIMS_CARRIERS:
			return {
				...state,
				carriers: action.payload,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
