class EfficiencyUnit {
	constructor({ units = ['', ''], decimal = 0, conversion = 1 }) {
		var _units = units;
		var _decimal = decimal;
		var _conversion = conversion;

		this.getUnits = () => _units;
		this.setUnits = (u) => (_units = u);

		this.getDecimal = () => _decimal;
		this.setDecimal = (dec) => (_decimal = dec);

		this.getConversion = () => _conversion;
		this.setConversion = (conv) => (_conversion = conv);
	}

	toString = (value = 0, { decimal = this.getDecimal() } = {}) => {
		if (value === null) value = 0;
		value = this.convertValue(value).toFixed(decimal);
		return `${value} ${this.getCurrentUnit()}`;
	};

	convertValue = (value) => +(value * this.getConversion());

	reverse = () => {
		return new EfficiencyUnit({
			decimal: this.getDecimal(),
			conversion: 1 / this.getConversion(),
			units: this.getUnits(),
		});
	};

	getCurrentUnit = () => `${this.getUnits()[0]}/${this.getUnits()[1]}`;

	format = (value = 0, { decimal = this.getDecimal() } = {}) => +this.convertValue(value).toFixed(decimal);
}

export default EfficiencyUnit;
