import Unit from './unit';

class temperatureUnit extends Unit {
	constructor({ conversion = 1, units = ['', ''], decimal = 0, convert = false, ConstantValue = 0 }) {
		super({ conversion, units, decimal, convert });

		var _ConstantValue = ConstantValue;

		this.getConstantValue = () => _ConstantValue;
		this.setConstantValue = (u) => (_ConstantValue = u);
	}

	reverse = () => {
		return new temperatureUnit({
			decimal: this.getDecimal(),
			conversion: 1 / this.getConversion(),
			units: [...this.getUnits()].reverse(),
			convert: true,
			ConstantValue: this.getConstantValue(),
		});
	};

	convertValue = (value, convert) => {
		return +(convert
			? this.getUnits()[0] === 'ºC'
				? value * this.getConversion() + this.getConstantValue()
				: (value - this.getConstantValue()) * this.getConversion()
			: value);
	};
}

export default temperatureUnit;
