import { IMPERSONATE, LOG_OUT } from '../auth/actionTypes';
import {
	ADD_MAINTENANCE,
	DELETE_MAINTENANCE,
	EDIT_MAINTENANCE,
	FETCH_MAINTENANCES,
	UPDATE_ACCUMULATED,
} from './actionTypes';

const initState = {
	maintenances: [],
	deviceMaintenances: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case EDIT_MAINTENANCE:
		case ADD_MAINTENANCE:
			return {
				...state,
				maintenances: action.maintenance,
			};
		case DELETE_MAINTENANCE:
		case UPDATE_ACCUMULATED:
		case FETCH_MAINTENANCES:
			return {
				...state,
				maintenances: action.maintenances,
			};
		case IMPERSONATE:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
