import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { Dispatch } from 'redux';
import compile from 'string-template/compile';
import { LOG_OUT } from '../features/auth/actionTypes';
import mqtt from '../features/rabbitmq';
import { errorFields } from '../utilities/util';

const errorInterceptor = (error: AxiosError, messages: Record<string, string>, dispatch: Dispatch<any>) => {
	if (axios.isCancel(error)) return;
	const getErrorCodeMessage = (code: string, options = {}) => {
		let errorCode = _.camelCase(code);
		let message;
		if (errorCode in messages) {
			message = messages[errorCode];
		} else {
			message = messages.commonError;
		}
		let compiler = compile(message);
		return compiler(options);
	};

	if (error.response) {
		let data: Record<string, unknown> = {};
		if (error.response?.data?.error) {
			if (typeof error.response?.data?.error === 'string') {
				data.message = error.response?.data?.error;
			} else {
				data = { ...error.response?.data?.error };
			}
		}
		data.catch = false;
		var errorCodeMessage;
		switch (error.response.status) {
			case 401:
				mqtt.end();
				delete window.unitSystem;
				delete window.main;
				errorCodeMessage = getErrorCodeMessage(data.code as string);
				switch (data.code) {
					case 'LOGIN_FAILED_EMAIL_NOT_VERIFIED':
						message.warn(
							{
								content: errorCodeMessage,
								key: 'LOGIN_FAILED_EMAIL_NOT_VERIFIED',
							},
							5
						);
						break;
					case 'LOGIN_FAILED':
						break;
					default:
						message.error(
							{
								content: messages['sessionExpired'],
								key: 'sessionExpired',
							},
							5
						);
						break;
				}
				dispatch({ type: LOG_OUT });
				axios.defaults.headers.common['Authorization'] = false;
				data.catch = true;
				return Promise.reject(data);
			case 418:
				errorCodeMessage = getErrorCodeMessage(data.message as string);
				data.catch = true;
				message.error(errorCodeMessage, 5);
				return Promise.reject(data);
			case 403:
				window.location.assign('/unauthorized');
				data.catch = true;
				return Promise.reject(data); //TODO: fix
			case 500:
				errorCodeMessage = getErrorCodeMessage('serverError');
				message.error({ content: errorCodeMessage, key: 'serverError' }, 5);
				data.catch = true;
				return Promise.reject(data); //TODO: fix
			default:
				break;
		}

		switch (data.code) {
			case 'LOGIN_FAILED_EMAIL_NOT_VERIFIED':
			case 'RESET_FAILED_EMAIL_NOT_VERIFIED':
			case 'EMAIL_NOT_FOUND':
			case 'AUTHORIZATION_REQUIRED':
			case 'INVALID_TOKEN':
			case 'INACTIVE_ACCOUNT':
				errorCodeMessage = getErrorCodeMessage(data.code);
				message.warn({ content: errorCodeMessage, key: data.code }, 5);
				data.catch = true;
				return Promise.reject(data); //TODO: fix
			case 'EREQUEST':
				const field = errorFields(JSON.parse(error.config.data), data.message) ?? '';
				errorCodeMessage = getErrorCodeMessage(data.code, {
					field: messages[field],
				});
				message.error({ content: errorCodeMessage, key: data.code }, 5);
				data.catch = true;
				return Promise.reject(data); //TODO: fix
			case 'MODEL_NOT_FOUND':
				return Promise.reject({});
			default:
				var errorCode = _.camelCase(data.code as string);
				if (process.env.NODE_ENV !== 'production') {
					/* eslint-disable-next-line */
					console.warn(`Error code '${errorCode}' is not being handle by axios global error handler`);
					if (!(errorCode in messages)) {
						/* eslint-disable-next-line */
						console.error(`Translation for error code '${errorCode}' does not exist!`);
					}
				}
				return Promise.reject(data); //TODO: fix
		}
	} else if (error.request) {
		// The request was made but no response was received

		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		message.error(messages.serverError, 5);
	} else {
		// Something happened in setting up the request that triggered an Error
		message.error(messages.commonError, 5);
	}
};

export default errorInterceptor;
