import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Root from '../../components/Root';
import { locale } from '../../constants/global';
import { FetchLanguage } from '../languages/actions';
import unitSystem from '../unitSystems';

const Language = () => {
	const lang = useSelector((state) =>
		state.language.language ? state.language.language : state.language.defaultLanguage
	);
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user.unitSystemConfig) unitSystem.setConfigurations(JSON.parse(user.unitSystemConfig));
		if (user.languageId === 2) dispatch(FetchLanguage('es_ES'));
	}, [user]);

	return <Root locale={locale} lang={lang} />;
};

export default Language;
