export const FETCH_DEVICE_MODELS = 'FETCH_DEVICE_MODELS';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const FETCH_DEVICE_MODELS_COUNT = 'FETCH_DEVICE_MODELS_COUNT';
export const EDIT_MODELS_SUCCESS = 'EDIT_MODELS_SUCCESS';
export const ADD_MODELS_SUCCESS = 'ADD_MODELS_SUCCESS';
export const DELETE_MODELS_SUCCESS = 'DELETE_MODELS_SUCCESS';
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_MODEL_SEND_TYPES = 'FETCH_MODEL_SEND_TYPES';
export const DEVICE_MODEL_STATUS = 'DEVICE_MODEL_STATUS';
export const FETCH_COMMANDS_TYPES = 'FETCH_COMMANDS_TYPES';
