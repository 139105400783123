import {
	CHANGE_CENTER_SELECT,
	CHANGE_CENTER_UPDATE,
	CHANGE_CLUSTER_DEVICES,
	CHANGE_IDLE_COLOR,
	CHANGE_MAP_TYPE,
	CHANGE_MOVEMENT_COLOR,
	CHANGE_MUTE_NOTIFICATION,
	CHANGE_PARKING_COLOR,
	CHANGE_SHOW_TRACE,
	CHANGE_TRACE_COLOR,
	CHANGE_TRACE_COLOR_BY_SPEED,
	CHANGE_TRAFIC,
	CLOSE_CONFIGURATION,
	FETCH_SPEED_RANGES_CONFIGURATION,
	INIT_MAP_CONFIGURATION,
	RESTORE_CONFIGURATION,
	SHOW_CONFIGURATION,
	START_LOADING_CONFIGURATION,
	STOP_LOADING_CONFIGURATION,
} from '../constants/actionTypes';
import { IMPERSONATE, LOG_IN, LOG_OUT } from '../features/auth/actionTypes';

const initState = {
	showConfiguration: false,
	loading: false,
	traffic: false,
	mapType: 'roadmap',
	centerOnSelect: false,
	centerOnUpdate: false,
	showTrace: false,
	traceColor: '#00bcd4',
	traceBySpeedIsActive: false,
	speedRangesConfiguration: [],
	movementColor: '#00bcd4',
	parkingColor: '#00bcd4',
	idleColor: '#00bcd4',
	unit: 'km',
	mapView: false,
	muteNotification: false,
	clusterDevices: false,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case CHANGE_MUTE_NOTIFICATION:
			return {
				...state,
				muteNotification: action.mute,
			};
		case IMPERSONATE:
		case LOG_IN:
			return {
				...state,
				...action.config,
				speedRangesConfiguration: action.speedRangesConfiguration,
			};
		case INIT_MAP_CONFIGURATION:
			return {
				...state,
				...action.config,
			};
		case RESTORE_CONFIGURATION:
			return {
				...state,
				...action.configuration,
			};
		case CHANGE_CENTER_SELECT:
			return {
				...state,
				centerOnSelect: action.centerOnSelect,
			};
		case CHANGE_CLUSTER_DEVICES:
			return {
				...state,
				clusterDevices: action.clusterDevices,
			};
		case CHANGE_CENTER_UPDATE:
			return {
				...state,
				centerOnUpdate: action.centerOnUpdate,
			};
		case CHANGE_SHOW_TRACE:
			return {
				...state,
				showTrace: action.showTrace,
			};
		case CHANGE_TRAFIC:
			return {
				...state,
				traffic: action.traffic,
			};
		case CHANGE_MAP_TYPE:
			return {
				...state,
				mapType: action.mapType,
			};
		case CHANGE_MOVEMENT_COLOR:
			return {
				...state,
				movementColor: action.color,
			};
		case CHANGE_PARKING_COLOR:
			return {
				...state,
				parkingColor: action.color,
			};
		case CHANGE_IDLE_COLOR:
			return {
				...state,
				idleColor: action.color,
			};
		case CHANGE_TRACE_COLOR:
			return {
				...state,
				traceColor: action.color,
			};
		case CHANGE_TRACE_COLOR_BY_SPEED:
			return {
				...state,
				traceBySpeedIsActive: action.traceBySpeedIsActive,
			};
		case FETCH_SPEED_RANGES_CONFIGURATION:
			return {
				...state,
				speedRangesConfiguration: action.speedRangesConfiguration,
			};
		case START_LOADING_CONFIGURATION:
			return {
				...state,
				loading: true,
			};
		case STOP_LOADING_CONFIGURATION:
			return {
				...state,
				loading: false,
			};
		case SHOW_CONFIGURATION:
			return {
				...state,
				showConfiguration: true,
			};
		case CLOSE_CONFIGURATION:
			return {
				...state,
				showConfiguration: false,
				loading: false,
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
