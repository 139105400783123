import { locale } from '../../constants/global';
import { LOG_OUT } from '../auth/actionTypes';
import { IMPERSONATE_SUCCESS } from '../impersonate/actionTypes';
import { SET_LANGUAGE } from './actionTypes';

const LanguageList = [
	{
		id: 1,
		name: 'English',
		shortName: 'en',
	},
	{
		id: 2,
		name: 'Español',
		shortName: 'es',
	},
];

const initState = {
	list: LanguageList,
	defaultLanguage: 'en_US',
	language: undefined,
	messages: locale['en_US'].messages,
	code: locale['en_US'].intl,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case IMPERSONATE_SUCCESS:
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
				messages: action.messages,
				code: locale[action.language].intl,
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
