import { LOG_OUT } from '../auth/actionTypes';
import { FETCH_ROLES } from './actionTypes';

const initState = {
	list: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_ROLES:
			return {
				...state,
				list: action.payload,
			};
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
