import { CLEAN_REDUCER } from '../../constants/actionTypes';
import { LOG_OUT } from '../auth/actionTypes';
import {
	ADD_ACCOUNTS_SUCCESS,
	DELETE_ACCOUNTS_SUCCESS,
	DISABLE_ACCOUNTS_SUCCESS,
	EDIT_ACCOUNTS_SUCCESS,
	FETCH_ACCOUNTS,
	FETCH_ACCOUNTS_COUNT,
} from './actionTypes';

const initState = {
	list: [],
	editable: {},
	count: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_ACCOUNTS:
			return {
				...state,
				list: action.payload,
			};
		case FETCH_ACCOUNTS_COUNT:
			return {
				...state,
				count: action.count,
			};
		case ADD_ACCOUNTS_SUCCESS: {
			let added = state.list.slice();
			if (state.count === 10) added.pop();
			added.unshift(action.payload);
			return {
				...state,
				list: added,
				count: state.count + 1,
			};
		}
		case DELETE_ACCOUNTS_SUCCESS: {
			return {
				...state,
			};
		}
		case DISABLE_ACCOUNTS_SUCCESS: {
			let disable = state.list.map((element) => {
				if (element.id !== action.id) {
					return {
						...element,
					};
				}
				return {
					...element,
					active: !element.active,
				};
			});
			return {
				...state,
				list: disable,
			};
		}
		case EDIT_ACCOUNTS_SUCCESS: {
			let edit = state.list.map((element) => {
				if (element.id !== action.payload.id) {
					return {
						...element,
					};
				}
				return {
					...element,
					...action.payload,
				};
			});
			return {
				...state,
				list: edit,
			};
		}
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
