import { AxiosRequestConfig } from 'axios';

type Props = {
	config: AxiosRequestConfig<unknown>;
	token?: string;
};
const apiVertionInterceptor = ({ config, token }: Props) => {
	if (config.url?.startsWith('/v2/')) {
		if (token) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			};
		}
	} else {
		if (token) {
			config.headers = {
				...config.headers,
				Authorization: token,
			};
		}
	}

	return config;
};

export default apiVertionInterceptor;
