import axios, { AxiosResponse } from 'axios';
import { REFRESH_TOKEN_ENDPOINT } from '../../constants/global';
import { isEmail } from '../../utilities/util';
import { RouteUser } from '../entities/routeUser.entity';

type Auth = {
	accessToken: string;
	ttl: number;
	created?: Date;
	userId: number;
	account: RouteUser;
};

type ImpersonateDto = {
	token: Auth;
	refreshToken: string;
	expiresIn: string;
};

type RenewingRefreshTokenDto = {
	refreshToken: string;
	accessToken: string;
	expiresIn: string;
	roles: number[];
};

type RevertImpersonateDto = {
	userId: number;
	impersonator: number;
	refreshToken: number;
};

type AuthServiceType = {
	Login: (auth: Credentials) => Promise<Auth>;
	Logout: (refreshToken: string) => Promise<AxiosResponse<void>>;
	Impersonate: (userId: number) => Promise<AxiosResponse<ImpersonateDto>>;
	RevertImpersonate: (data: RevertImpersonateDto) => Promise<AxiosResponse<void>>;
	UpdateRefreshToken: (oldRefreshToken: string) => Promise<RenewingRefreshTokenDto>;
};

type Credentials = {
	username?: string;
	email?: string;
	password: string;
	remember?: boolean;
	ttl?: number;
};

const Login = async (auth: Credentials): Promise<Auth> => {
	let options: Credentials = {
		password: auth.password,
		remember: auth.remember,
	};

	if (isEmail(auth.username)) {
		options.email = auth.username;
	} else {
		options.username = auth.username;
	}
	const { data } = await axios.post<Auth>(`/v2/auth/login`, options);

	return data;
};

const Logout = async (refreshToken: string): Promise<AxiosResponse<void>> =>
	await axios.post(`/v2/auth/logout`, { refreshToken });

const Impersonate = async (userId: number): Promise<AxiosResponse<ImpersonateDto>> =>
	axios.post<ImpersonateDto>(`/v2/auth/impersonate`, { userId });

const RevertImpersonate = async (data: RevertImpersonateDto): Promise<AxiosResponse<void>> =>
	await axios.post(`/v2/auth/impersonate/revert/`, data);

const UpdateRefreshToken = async (oldRefreshToken: string): Promise<RenewingRefreshTokenDto> => {
	const { data } = await axios.post(REFRESH_TOKEN_ENDPOINT, {
		refreshToken: oldRefreshToken,
	});
	return data;
};

const AuthService: AuthServiceType = {
	Login,
	Logout,
	Impersonate,
	RevertImpersonate,
	UpdateRefreshToken,
};

export default AuthService;
