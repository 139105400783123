import { LOG_OUT } from '../auth/actionTypes';
import { CLEAN_REDUCER, CLEAR_ALERTS, FEATCH_ALERTS, NEW_ALERT } from './actionTypes';

const initState = {
	alerts: [],
	newCount: 0,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FEATCH_ALERTS:
			return {
				...state,
				alerts: action.alerts,
				newCount: action.count,
			};
		case NEW_ALERT:
			return {
				...state,
				alerts: [action.alert, ...state.alerts].splice(0, 10),
				newCount: action.alert.seen ? state.newCount : state.newCount + 1,
			};
		case CLEAR_ALERTS:
			return {
				...state,
				alerts: action.alerts,
				newCount: 0,
			};
		case CLEAN_REDUCER:
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
