import { RABBIT_CONECTED, RABBIT_DISCONECTED, RABBIT_MAIN_MESSAGE, RABBIT_MESSAGE } from '../constants/actionTypes';
import { LOG_OUT } from '../features/auth/actionTypes';

const initState = {
	rabbitConnected: false,
	rabbitConnecting: false,
	hideMessage: null,
	hideMainMessage: null,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case RABBIT_MAIN_MESSAGE: {
			return {
				...state,
				hideMainMessage: action.callback,
			};
		}
		case RABBIT_MESSAGE: {
			return {
				...state,
				hideMessage: action.callback,
				rabbitConnecting: action.connecting,
			};
		}
		case RABBIT_CONECTED: {
			return {
				...state,
				rabbitConnected: true,
			};
		}
		case RABBIT_DISCONECTED: {
			return {
				...state,
				rabbitConnected: false,
			};
		}
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
