import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import axios from 'axios';
import { REFRESH_TOKEN_ENDPOINT } from '../constants/global';
import { cleanAuthInformation, setNewTokens } from '../features/auth/actions';
import { REMOVE_AUTHENTICATION } from '../features/auth/actionTypes';
import { useAppDispatch, useAppSelector } from '../OptimusRoutes/hooks/redux.hooks';
import { AuthService } from '../OptimusRoutes/services';
import { getUtcNow, toUtcDate } from '../utilities/dateUtils';
import apiVertionInterceptor from './apiVersion.interceptor';
import errorInterceptor from './error.interceptor';
import guestInterceptor from './guest.interceptor';

type Props = {
	children: JSX.Element;
};

axios.defaults.baseURL = '/api';

const LOGOUT_ENDPOINT = '/v2/auth/logout';
const LOGIN_ENDPOINT = '/v2/auth/login';
const sleep = (seconds: number) => {
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const AxiosInterceptor = ({ children }: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const token = useAppSelector((state) => state.auth.token);
	const messages = useAppSelector((state) => state.language.messages);
	const refreshToken = useAppSelector((state) => state.auth.refreshToken);
	const expiresIn = useAppSelector((state) => state.auth.expiresIn);
	const isRenewing = useRef(false);
	const accessToken = useRef(token);

	const restoreSession = useCallback(async () => {
		try {
			isRenewing.current = true;
			const data = await AuthService.UpdateRefreshToken(refreshToken);
			dispatch(setNewTokens(data.accessToken, data.refreshToken, data.expiresIn, data.roles));
			accessToken.current = data.accessToken;
		} catch (error) {
			dispatch({ type: REMOVE_AUTHENTICATION });
		} finally {
			isRenewing.current = false;
		}
	}, [refreshToken]);

	useLayoutEffect(() => {
		let authinterceptor = -1;

		if (token) {
			accessToken.current = token;
			authinterceptor = axios.interceptors.request.use(async (config) => {
				try {
					if (expiresIn) {
						const currectUtcDate = getUtcNow();
						const expiresInDate = toUtcDate(expiresIn);
						const shouldRenewSession =
							currectUtcDate >= expiresInDate &&
							!config.url?.includes(REFRESH_TOKEN_ENDPOINT) &&
							!config.url?.includes(LOGIN_ENDPOINT) &&
							!config.url?.includes(LOGOUT_ENDPOINT);
						if (shouldRenewSession) {
							if (!isRenewing.current) {
								await restoreSession();
							}
						}
					}
					if (isRenewing.current && !config.url?.includes(REFRESH_TOKEN_ENDPOINT)) {
						await sleep(7);
					}
					apiVertionInterceptor({ config, token: accessToken.current });
				} catch (error) {
					dispatch(cleanAuthInformation());
				} finally {
					return config;
				}
			});
		}
		return () => {
			if (authinterceptor > -1) {
				axios.interceptors.request.eject(authinterceptor);
			}
		};
	}, [token, expiresIn]);

	useLayoutEffect(() => {
		const guestInterceptorId = axios.interceptors.request.use(async (config) => {
			guestInterceptor({ config });
			return config;
		});
		return () => {
			if (guestInterceptorId > -1) {
				axios.interceptors.request.eject(guestInterceptorId);
			}
		};
	}, []);
	useEffect(() => {
		const errorinterceptor = axios.interceptors.response.use(
			function (response) {
				return response;
			},
			(error) => errorInterceptor(error, messages, dispatch)
		);
		return () => {
			axios.interceptors.response.eject(errorinterceptor);
		};
	}, []);

	return children;
};

export default AxiosInterceptor;
