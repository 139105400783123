import { DeviceDto } from '@/features/devices/device.dto';
import { Action } from '@/features/groups/actions';
import { GroupDto } from '@/features/groups/group.dto';
import { UserDto } from '@/features/users/users.dto';
import { ActionType } from '@/types/actionType';

type State = {
	groups: GroupDto[];
	devices: DeviceDto[];
	accounts: UserDto[];
};

const initialState: State = {
	groups: [],
	devices: [],
	accounts: [],
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.GROUPS_FETCH_INFO:
		case ActionType.GROUPS_EDIT:
		case ActionType.GROUPS_UN_SHARE:
		case ActionType.GROUPS_DELETE:
			return {
				...state,
				groups: action.payload as GroupDto[],
			};
		case ActionType.GROUPS_ADD:
			return {
				...state,
				groups: [...state.groups, action.payload as GroupDto],
			};
		case ActionType.GROUPS_FETCH_DEVICES:
			return {
				...state,
				devices: action.payload as DeviceDto[],
			};
		case ActionType.GROUPS_FETCH_ACCOUNTS:
			return {
				...state,
				accounts: action.payload as UserDto[],
			};

		case ActionType.CLEAN_REDUCER:
		case ActionType.LOG_OUT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
